<template>
    <v-container
        tag="section"
    >
        <v-row
            justify="center"
        >        
            <v-col
                cols="12"
                lg="9"
                xl="7"
                class="block-media-lk"
            >
                <base-material-card
                    color="warning"
                    class="px-sm-8 px-md-16 px-2 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                          Запуск новичка
                        </div>
                    </template>

                    <v-card-text
                        class="mt-10 mb-10 card-media-lk"
                    >
                    <p class="mb-7">
                      Сайт по запуску - Модульный сайт с материалами для кандидатов и новичков компании LR. Весь материал выдается пошагово, что позволяет постепенно включаться в процесс работы. Смотри в описании пароли от модулей.                       
                      </p>
                      <p class="mb-5">
                        Цель - Принятие решения к регистрации, запуск новичка. Удобное место, где хранятся все нужные материалы.
                      </p>
                      <p class="mb-10">
                        <a href="http://freestyle21.com/rustart/" target="_blank">Ссылка на сайт Freestyle21</a>
                      </p>
                        <v-expansion-panels
                            popout
                            focusable
                            class="exp-panel-title"
                        >
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                              <span>
                                  <v-icon class="mr-2" color="red">mdi-numeric-1-box-multiple</v-icon>
                                  Инструкция по запуску новичка
                              </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <p class="mt-10">
                                <a href="https://teletype.in/@newlvl21/Lovs8Voqq9p" target="_blank">Инструкция по запуску новичка</a>
                              </p>                   
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "ReqHealthLearn",
    data: () => ({
        dateNow: new Date().toISOString().substr(0, 10),
        dialog: true,
        paytimeUser: ''
    }),
    computed: {
        ...mapGetters({
          packageAll: 'user/getProfileAll'
        }),
        datePay() {
            let D = new Date()
            D.setDate(D.getDate() + 7)
            return D.toISOString().substr(0, 10)
        }
    },
    created () {
        this.initializeUserPackage()
    },
    methods: {
        ...mapActions({
            loadPackageAll: 'user/loadProfileAll',
            loadProfilePackage: 'user/loadProfilePackage',
        }),
        initializeUserPackage() {
            this.loadProfilePackage()
                .then((response) => {
                    this.loadingPackage = false
                    this.paytimeUser = response.data.paytime
                })
        },
    },
    mounted() {
        this.loadPackageAll()
        this.loadProfilePackage()
    }
}
</script>

<style lang="sass">
.p-title
  text-transform: uppercase
  font-weight: bold

.exp-panel-title .v-expansion-panel-header
    font-size: 14px

.exp-panel-title .v-expansion-panel--active
    border: 1px solid #69db69

.img-secret
  max-width: 300px
  margin-right: 10px

.img-blic
  max-width: 300px
  margin: 10px
  border: 1px solid #ccc
  border-radius: 10px

@media(max-width: 600px)
    .block-media-lk
        padding: 0

    .card-media-lk
        padding: 0

</style>
